import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Employee } from '../../../modules/cs/common/components/employee';

import '../../generic-page.scss';

const CSShadowPalaceF2Page: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shadow Palace - Floor 2</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/shadow_floor_2.png"
            alt="Shadow Palace"
          />
        </div>
        <div className="page-details">
          <h1>Shadow Palace - Floor 2</h1>
          <h2>Guide for the second floor of Shadow Palace.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="MVP employees" />
        <div className="employee-container">
          <Employee slug="yen-xing-lanchester" mode="icon" enablePopover />
          <Employee slug="alex" mode="icon" enablePopover />
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="john-mason" mode="icon" enablePopover />
        </div>
        <p>
          Esterosa barrier is way thicker in SP than her normal one, but
          Yen/Alex can dispel it allowing you to clear the stages with her
          faster.
        </p>
        <div className="employee-container">
          <Employee slug="lee-jisoo" mode="icon" enablePopover />
          <Employee slug="awakened-seo-yoon" mode="icon" enablePopover />
          <Employee slug="frederick-doma" mode="icon" enablePopover />
        </div>
        <p>
          Claudia is one of the enemies on this floor so heal blocking is useful
          here.
        </p>
        <SectionHeader title="Enemies" />
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_ZODIAC_CHARLOTTE.png"
          alt="Shadow Enemy"
        />
        <h4>Shadow Esterosa</h4>
        <p>
          Her shields are thicker and it takes a while to go through them, but
          if you have Alex/Yen Xing you can easily make her vulnerable.
        </p>
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_VATICAN_CLOUDIA.png"
          alt="Shadow Enemy"
        />
        <h4>Shadow Claudia</h4>
        <p>
          Not only does she heal a lot, but also damages anyone that stays in
          her healing aura. Bring a heal blocker to deal with the healing
          easier.
        </p>
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_ALPHA_JIA.png"
          alt="Shadow Enemy"
        />
        <h4>Shadow Shin Jia</h4>
        <p>
          Stronger clones + bigger knockback, so use more ranged characters to
          deal with her.
        </p>
        <h4>Ship</h4>
        <p>
          It’s similar to the normal Lake ship, but the shield also gives ASPD
          and the ult only pushes units back without pulling the backline to the
          front.
        </p>
        <SectionHeader title="Restrictions" />
        <ul>
          <li>Room 1 - Defenders are banned</li>
          <li>Room 2 - Snipers are banned</li>
          <li>Room 3 - Strikers are banned</li>
          <li>Room 4 - Max 25 deployment cost</li>
          <li>Room 5 - Max 25 deployment cost and Soldiers are banned</li>
        </ul>
        <SectionHeader title="Important" />
        <p>
          <strong>
            Any room can be cleared with dozens, if not more, different teams
          </strong>
          . Obviously, if you have Yuna/Hilde/ASY, you can slap her into your
          team and she will carry you, but the example teams below are targeted
          toward players that don’t really have any awakened units.
        </p>
        <SectionHeader title="Room 1" />
        <p>
          Bring Alex/Yen Xing to deal with Shadow Este and the floor will be a
          cakewalk. You can’t use defenders here, so give Alex - or your other
          Striker - HP+HP (or HuMaGorgor from your tank) and she will carry you.
          Still, if you lack Alex or other barrier removers, you just need to
          bring more firepower to destroy the barriers made by Esterosa.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="sigma" mode="icon" enablePopover />
          <Employee slug="alex" mode="icon" enablePopover />
          <Employee slug="xiao-lin" mode="icon" enablePopover />
          <Employee slug="claudia-nelson" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="sylvia-lena-cooper" mode="icon" enablePopover />
          <Employee slug="cindy-looper" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 2" />
        <p>
          This time snipers are banned, so you can’t use Edel the carry monster
          here. Since Claudia’s circle only hurts ground units, you can use Air
          units (Rosa, Janus) to basically have free real estate on her while a
          tank occupies her.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="alex" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="frederick-doma" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="shin-jia" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="kim-sobin" mode="icon" enablePopover />
          <Employee slug="cindy-looper" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 3" />
        <p>
          Strikers are banned here, but it’s against Shadow Shin Jia, so it’s
          not that bad. Use snipers to kill her while your frontline will hold
          her ground and you should be fine.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="serapel" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="xiao-lin" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="claudia-nelson" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
          <Employee slug="kim-sobin" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 4" />
        <p>
          No class restrictions, but you can’t go over 25 cost this time. Use
          flying units + snipers and keep Shadow Claudia/Shin Jia duo occupied
          with a tank+healer.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="alex" mode="icon" enablePopover />
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="rosaria-le-friede" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="frederick-doma" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="cindy-looper" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 5" />
        <p>
          25 cost limit and soldier ban. Not like anyone is using soldiers on
          this floor, but hey! Alex/Yen Xing will be useful here because you
          again have to fight against Thicksterosa here. So use either of them
          to remove her shield. Then stack snipers and clear the floor!
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="alex" mode="icon" enablePopover />
          <Employee slug="xiao-lin" mode="icon" enablePopover />
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="claudia-nelson" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="frederick-doma" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="cindy-looper" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CSShadowPalaceF2Page;

export const Head: React.FC = () => (
  <Seo
    title="Shadow Palace 2 guide | Counter Side | Prydwen Institute"
    description="Guide for the second floor of Shadow Palace."
  />
);
